/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-01-17 23:41:39
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-04 15:43:59
 */
import Vue from 'vue'
import OpenAppPopUp from './OpenAppPopUp.vue'
let OpenAppPopUpVue: any

function createOpenAppPopUp() {
  const vnode = new Vue({
    render: h => h(OpenAppPopUp)
  }).$mount()
  document.body.appendChild(vnode.$el)
  return vnode.$children[0]
}

export function openAppPopUp() {
  if (!OpenAppPopUpVue) {
    OpenAppPopUpVue = createOpenAppPopUp()
  }
  OpenAppPopUpVue.open()
  return OpenAppPopUpVue
}
