















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'OpenAppPopUp'
})
export default class OpenAppPopUp extends Vue {
  show = true

  open() {
    this.show = true
  }
}
