/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-10-13 17:46:19
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-04 15:57:36
 */

import { service, javaService } from '@/common/utils/request'
import { APP_VERSION } from '@/config'
import { CommonModule } from '@/store/modules/common'
import qs from 'qs'

/**
 * 新接口 进入直播间
 * @returns
 */
export function liveRoomEnter(params: {
  anchor_id: number | string
  stream: string
}): IRes<LiveInfo> {
  return service.post('/live/enter', qs.stringify(params))
}
/**
 * 检测直播状态
 * @param params
 * @returns
 */
export function liveCheck(params: {
  anchor_id: number | string
  stream: string
}): IRes<any> {
  return service.post('/live/check', qs.stringify(params))
}

/**
 * 热门动态列表
 */
export function getDynamicList(
  params: PageParams = {}
): IRes<{
  dynamic_default_image: DynamicImage[]
  list: DynamicDetail[]
}> {
  return service.get('/moments/hot/dynamic/list', {
    params,
    headers: {
      'app-version': APP_VERSION,
      'device-id': CommonModule.deviceId
    }
  })
}

/**
 * 获取动态详情
 */
export function getDynamicDetail(id: number | string): IRes<DynamicDetail> {
  return service.get('/moments/dynamic/detail', {
    params: {
      dynamic_id: id
    },
    headers: {
      'app-version': APP_VERSION,
      'device-id': CommonModule.deviceId
    }
  })
}

export function getDynamicComment(params: any) {
  return service.get('moments/user/dynamic/comment/list', {
    params,
    headers: {
      'app-version': APP_VERSION,
      'device-id': CommonModule.deviceId
    }
  })
}
/**
 * 获取用户动态
 * @param touid 页码
 * @param page 被访问的用户uid
 * @param uid 用户ID（游客为0）
 */
export function getUserMoments(
  touid: string,
  page: number,
  uid = '0'
): IRes<{ count: number; limit: number; list: UserDynamic[] }> {
  return service.get(`/moments/user/moments/${uid}/${touid}/${page}`, {
    headers: {
      'app-version': APP_VERSION,
      'device-id': CommonModule.deviceId
    }
  })
}

/**
 * 获取视频详情
 * @param videoId 视频id
 */
export function getVideoInfo(videoId: string): IRes {
  return service.get('/api/v1/4/video/getInfo', {
    params: {
      videoId
    }
  })
}

/**
 * 获取包名信息
 * @param channel
 * @returns
 */
export function getApkInfo(channel = 'xcx') {
  return service.get(`/api/channel/apk/info/${channel}`)
}

/**
 * 个人信息收集次数
 * @param params
 * @returns
 */
export function getInformationCollectNum(params: { key: string }) {
  return service.get('/api/kigo/user/get/personal/info/num', { params })
}

/**
 * 通过分享id，获取分享配置
 * @param params
 * @returns
 */
export function getGameShareInfo(params: { id: string; uid: string }) {
  return javaService.get('/kigo-room-api/game/shareConfig/userShare', {
    params
  })
}
