/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-10-13 12:27:28
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-23 17:55:28
 */
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  MutationAction
} from 'vuex-module-decorators'
import store from '@/store'
import { getDynamicList, getApkInfo } from '_index/api/share'
import { getPlatform } from '@/common/utils'

@Module({ dynamic: true, store, name: 'Share' })
class Share extends VuexModule {
  /**
   * 首页直播间数据
   */
  liveRoomList: any[] = []
  /**
   * 首页视频列表
   */
  videoHomeList: any[] = []

  get sortVideoList() {
    const length = this.videoHomeList.length
    let videoArr = []
    if (length < 10) {
      const cha = 10 - length
      videoArr = this.videoHomeList.slice(0, cha)
    }
    return videoArr
  }

  finished = false
  loading = true

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }

  shareLayoutRefresh = false

  @Mutation
  setShareLayoutRefresh(loading: boolean) {
    this.shareLayoutRefresh = loading
  }

  dynamicList: DynamicDetail[] = []
  dynamic_default_image: DynamicImage[] = []
  @MutationAction({
    mutate: ['dynamicList', 'dynamic_default_image']
  })
  async getDynamicList() {
    const { data } = await getDynamicList({
      page: 1,
      page_size: 18
    })
    return {
      dynamicList: data.list,
      dynamic_default_image: data.dynamic_default_image
    }
  }

  showOpenAppPopUp = false

  @Mutation
  setShowOpenAppPopUp(show: boolean) {
    this.showOpenAppPopUp = show
  }

  apkUrl = ''

  @MutationAction({ mutate: ['apkUrl'] })
  async getApkInfo() {
    const { data } = await getApkInfo('kz5')
    return {
      apkUrl: data.apk_url
    }
  }
}

export const ShareModule = getModule(Share)
