




















import { Component, Mixins } from 'vue-property-decorator'
import { OutsidePageMixin } from '@/mixins/outsidePage'
import { isAndroid, isIOS } from '@/common/utils/device'
import { toAppMarketEnjoy } from '@/common/utils/index'
// 生成随机数
function newguid(length = 16) {
  let guid = '';
  for (let i = 1; i <= length; i++) {
    const n = Math.floor(Math.random() * 16.0).toString(16);
    guid += n;
  }
  return guid;
}
@Component({
  name: 'Ad'
})
export default class Ad extends Mixins(OutsidePageMixin){
  isMobile = false
  isPc = false
  goApp() {
    let platform = 'PC';
    const eventName = 'event';
    if (this.isMobile) {
      if (isAndroid) {
        platform = 'Android';
      }else {
        platform = 'iOS';
      }
    }
    // 点击下载统计
    if (window.uet_report_conversion) {
      window.uet_report_conversion(eventName, platform)
    }
    if (platform == 'iOS') {
      this.$toast('iOS版本暂未发布，请使用安卓平台下载')
      return;
    }
    toAppMarketEnjoy()
  }
  goLeidian() {
    window.open('https://www.ldmnq.com', '_blank')
  }
  created() {
      const isMobile = /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent) //是否为移动终端
      if (isMobile) {
        this.isMobile = true
      }else {
        this.isPc = true
      }
  }
  mounted() {
    // 浏览统计
    window.uetq = window.uetq || [];
    window.uetq.push('set', { 'pid': {
        'em': new Date().getTime(), // Replace with the variable that holds the user's email address.
        'ph': newguid(11), // Replace with the variable that holds the user's phone number.
      } });
    window.uetq.push('event', 'null', {"event_label":"open"});
  }
}
