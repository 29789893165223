import { openAppPopUp } from '@/components/OpenAppPopUp'
import { ShareModule } from '_index/modules/share'
import { openScheme, openSchemeNew, toAppMarketEnjoy } from '@/common/utils'
import {
  callAppFunc_openApp,
  callAppFunc_webToThirdApp
} from '@/common/utils/bridge'
import { toAppMarket } from '@/common/utils'
import { Component, Vue } from 'vue-property-decorator'

const ua = navigator.userAgent
/**
 * 是否iOS设备
 */
const isIOS = /iphone|ipad|ipod/i.test(ua)

@Component
export class OutsidePageMixin extends Vue {
  openSchemeing = false
  async handleOpenApp(queryUrl = '') {
    if (this.openSchemeing) return
    this.openSchemeing = true
    try {
      if (this.$platform === 'chekchek') {
        const { message, data } = await callAppFunc_openApp({
          appPackageName: 'com.kigo.app',
          //schemeUrl: `live.kigo://kigo.com${queryUrl}`,
          schemeUrl: 'live.kigo://',
          appDownloadUrl: ShareModule.apkUrl
        })
        this.$toast(
          `${message}${
            data.progress ? '，进度：' + data.progress * 100 + '%' : ''
          }`
        )
      } else if (this.$platform == 'kixmix') {
        if (isIOS) {
          return
        }
        callAppFunc_webToThirdApp({
          appPackageName: 'com.kigo.app'
        })
        console.log('kixmix')
      } else if (
        this.$platform === 'weixin' ||
        (isIOS && this.$platform === 'qq')
      ) {
        openAppPopUp()
      } else {
        // const ToDownLoadPopUp = openToDownLoadPopUp()
        try {
          await openScheme(
            `live.kigo://kigo.com${queryUrl}`,
            3000
            //isIOS ? 11000 : 5500
          )
          //await openScheme('live.kigo://', isIOS ? 11000 : 5500)
          // ToDownLoadPopUp.close()
        } catch (error) {
          console.log('openScheme error', error)
          toAppMarket()
          // ToDownLoadPopUp.changeState(false)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.openSchemeing = false
    }
    console.log('打开APP')
  }

  async handleOpenEnjoyApp(downLoadUrl = '', queryUrl = '') {
    if (this.openSchemeing) return
    this.openSchemeing = true
    try {
      if (this.$platform === 'chekchek') {
        const { message, data } = await callAppFunc_openApp({
          appPackageName: 'com.djll.enjoy',
          //schemeUrl: `live.kigo://kigo.com${queryUrl}`,
          schemeUrl: 'live.djll://',
          appDownloadUrl: ShareModule.apkUrl
        })
        this.$toast(
          `${message}${
            data.progress ? '，进度：' + data.progress * 100 + '%' : ''
          }`
        )
      } else if (this.$platform == 'kixmix') {
        if (isIOS) {
          return
        }
        callAppFunc_webToThirdApp({
          appPackageName: 'com.djll.enjoy'
        })
        console.log('kixmix')
      } else if (this.$platform === 'weixin' || (isIOS && this.$platform === 'qq')) {
        openAppPopUp()
      } else {
        // const ToDownLoadPopUp = openToDownLoadPopUp()
        try {
          await openScheme(
            `djll.enjoy://com.djll.enjoy`,
            3000
            //isIOS ? 11000 : 5500
          )
          //await openScheme('live.djll://', isIOS ? 11000 : 5500)
          // ToDownLoadPopUp.close()
        } catch (error) {
          console.log('openScheme error', error)
          if (isIOS) {
            // ios暂不下载
            this.$toast('iOS版本暂未发布，请使用安卓手机下载！');
            // window.open('https://www.pgyer.com/RfrEAS')
          } else {
            toAppMarketEnjoy(downLoadUrl)
          }
          // ToDownLoadPopUp.changeState(false)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.openSchemeing = false
    }
    console.log('打开APP')
  }
}
